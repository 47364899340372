import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ConfigProvider } from "antd";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})
root.render(
  <React.StrictMode>
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#468499',
        borderRadius: 2,
        colorBgContainer: '#F5F5F5',    // Background color
        colorText: '#404040',
        colorPrimaryHover: '#224477'
      },
    }}
  >
    <QueryClientProvider client={queryClient}>
    <App />
    </QueryClientProvider>
  </ConfigProvider>
  </React.StrictMode>
);
