import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import LoadingPage from "./loading";
function ProtectedRoute({ element }) {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadingPage />;
  }
  console.log({user})
  return Object.keys(user).length > 0 ? element : <Navigate to="/" />;
}

export default ProtectedRoute;
