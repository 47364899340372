import React, { useState } from "react";
import { Button, Grid, Menu, Space, theme, Dropdown } from "antd";
import { MenuOutlined, HomeOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { useAuth } from "./AuthContext";
import { auth } from "./googleSignIn/config";
import logo from "../assets/images/CM.png";
import { useNavigate, useLocation } from "react-router-dom";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const CircleImage = ({ src, alt, size = 100 }) => {
  return (
    <div className="circle-image" style={{ width: size, height: size }}>
      <img src={src} alt={alt} style={{ width: "100%", height: "100%", borderRadius: "50%" }} />
    </div>
  );
};
export default function App() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { token } = useToken();
  const screens = useBreakpoint();
  const location = useLocation();

  // Access the current pathname
  const currentPath = (location.pathname).replace("/", "");
  // console.log({screen:screens.md})
  const items = [
    {
      key: "1",
      label: "Profile",
      icon: <UserOutlined />,
      onClick: () => handleProfileClick(),
    },
    {
      key: "2",
      label: (
        <a target="_blank" rel="noopener noreferrer">
          Settings
        </a>
      ),
      icon: <SettingOutlined />,
      disabled: true,
    },
    {
      key: "3",
      label: (
        <a href="/" rel="noopener noreferrer" onClick={() => auth.signOut()}>
          Log Out
        </a>
      ),
      icon: <LogoutOutlined />,
    },
  ];
  const menuItems = [
    {
      label: "Dashboard",
      key: "dashboard",
      onClick: () => navigate("/dashboard"),
      
    },
    {
      label: "Chat",
      key: "chat",
      disabled: true,
    },
  ];

  const [current, setCurrent] = useState(currentPath);
  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };
  const handleProfileClick = () => {
    setCurrent("");
    navigate("/profile");
  };

  const styles = {
    container: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      margin: "0 auto",
      maxWidth: token.screenXL,
      padding: screens.md ? `0px ${token.paddingLG}px` : `0px ${token.padding}px`
    },
    header: {
      // backgroundColor: token.colorBgContainer,
      // borderBottom: `${token.lineWidth}px ${token.lineType} ${token.colorSplit}`,
      position: "relative"
    },
    logo: {
      display: "block",
      height: token.sizeLG,
      left: "50%",
      position: screens.md ? "static" : "absolute",
      top: "50%",
      transform: screens.md ? " " : "translate(-50%, -50%)"
    },
    menu: {
      backgroundColor: "transparent",
      borderBottom: "none",
      lineHeight: screens.sm ? "4rem" : "3.5rem",
      marginLeft: screens.md ? "0px" : `-${token.size}px`,
      width: screens.md ? "inherit" : token.sizeXXL
    },
    menuContainer: {
      alignItems: "center",
      display: "flex",
      gap: token.size,
      width: "100%"
    }
  };

  return (
    <nav style={styles.header}>
      <div style={styles.container}>
        <div style={styles.menuContainer}>
          <img src={logo} height={45} alt="Logo" />
          <Menu
            style={styles.menu}
            mode="horizontal"
            items={menuItems}
            onClick={onClick}
            selectedKeys = {current}
            overflowedIndicator={
              <Button type="text" icon={<MenuOutlined />}></Button>
            }
          />
        </div>
        <Space>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
          arrow
        >
          <a>
            <CircleImage src={user?.photoURL} alt="My Image" size={45} />
          </a>
        </Dropdown>

        </Space>
      </div>
    </nav>
  );
}