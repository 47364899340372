import React from 'react';
import { Image, Layout } from 'antd';
import logo from '../../assets/images/logo.png';

const { Header } = Layout;

const TopBar = () => (
  <Header style={{ background: 'transparent', padding: '10px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Image src={logo} preview={false} width={20} style={{ marginRight: '8px' }} />
      <span className='inter text-red' style={{ fontSize: '14px' }}>Covenant</span>
      <span className='inter' style={{ fontSize: '14px', marginLeft: '2px' }}>Companion</span>
    </div>
  </Header>
);

export default TopBar;
