// index.js or App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignIn from "./Pages/SignIn";
import Dashboard from "./Pages/Dashboard";
import Profile from "./Pages/SetUpProfile";
import Onboarding from "./Pages/Onboarding";
import "./App.css";
import "./Essentials.css";
import ProtectedRoute from "./Components/ProtectedRoute";
import { AuthProvider } from "./Components/AuthContext";
function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<SignIn />} />
          {/* <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} /> */}
          <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
          <Route path="/onboarding" element={<ProtectedRoute element={<Onboarding />} />} />
          {/* Uncomment and use this for dynamic routing */}
          {/* <Route path="/profile/:id" element={<UserProfile />} /> */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
