import { useEffect } from "react";
import { auth, provider } from "../../Components/googleSignIn/config";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Button, Image } from "antd";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import { GoogleOutlined } from "@ant-design/icons";
import LoadingPage from "../../Components/loading";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import api from "../../lib/axios";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import headerImage from "../../../src/assets/images/cc_slider_image.png";
import logo from "../../../src/assets/images/logo.png";

import "./SignIn.css";
import { useAuth } from "../../Components/AuthContext";
export default () => {
  const { user, loading } = useAuth();
  const content = [
    {
      start: "What is",
      nextStart: "Covenant",
      red: "Companion",
      end: "",
      content: "Where Reformed Singles Meet! Find someone who loves God, loves you, and never skips a theology book sale.",
    },
    {
      start: "Create your",
      nextStart: "Marriage",
      red: " Profile",
      end: "",
      content: "Who are you? Why should someone marry you? Your solid Christian character or your ability to recite the Apostle's Creed backwards?",
    },
    {
      start: "Find yourself that",
      nextStart: "",
      red: " Predestined",
      end: "Match",
      content: "Discover someone who shares your beliefs and values. Why settle when your perfect match is predestined?",
    },
  ];

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const navigate = useNavigate();

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      if (user.isProfileComplete)
        navigate("/profile");
      else
        navigate("/onboarding");
    }
  }, [user, navigate]);
  const addUser = async (userDetails) => {
    const response = await api.post(`/user`, userDetails);
    return response.data;
  };

  const addUserMutationHook = useMutation({
    mutationFn: addUser,
  });
  const handleLogin = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        const user = result.user;

        try {
          const { data } = await fetchUserData(user.uid);
        } catch (err) {
          if (err.status === 404) {
            addUserMutationHook.mutate({
              id: user.uid,
              email: user.email,
              fullName: user.displayName,
              profileImage: user.photoURL,
              isPastorVerified: false,
              interests: [],
              liked_profiles: [],
              isProfileComplete: false,
            });
          }
        }
        navigate("/onboarding");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchUserData = async (uid) => {
    const response = await api.get(`/user/${uid}`);
    return response.data;
  };

  if (loading) return <LoadingPage />;

  return (
    <div className="container" style={{ position: "relative", overflowY: "hidden" }}>
      {/* Header and content */}
      {isMobile ? (
        <Swiper modules={[Pagination]} slidesPerView={1} pagination={{ clickable: true }} onSwiper={(swiper) => console.log(swiper)} onSlideChange={() => console.log("slide change")}>
          {content.map((item, index) => (
            <SwiperSlide key={index}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", maxHeight: "100vh", textAlign: "center" }}>
                {/* Header image */}
                <Image
                  src={headerImage}
                  preview={false}
                  style={{
                    transform: `translateX(-${index * 33.33}%)`, // Shift the image based on section index
                    width: "300%", // Make the image width 3 times the container to show one-third per section
                    height: "80vh",
                    maxHeight: "500px",
                    // objectFit: "contain", // Maintain the aspect ratio and cover the container
                  }}
                />
                {/* Content */}
                <div className="pointer" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", minHeight: "30vh", justifyContent: "center" }}>
                  <Image src={logo} preview={false} className="cc-logo" />
                  <div className="inter-xxl">
                    <span>{item.start}</span><span className="text-red">{item.nextStart ? <span style={{ color: "black" }}><br/>{item.nextStart}</span> : ""}{item.red}</span> {item.end}
                  </div>
                  <div className="inter-md text-grey" style={{ marginTop: "10px" }}>
                    {item.content}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div>
          {/* Desktop view: Static header image */}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
            <img src={headerImage} alt="Covenant Matrimony Logo" width={600} />
          </div>
          {/* Scrollable content */}
          <Swiper
            modules={[Pagination]}
            slidesPerView={1}
            pagination={{ clickable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
            style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "50vh" }}
          >
            {content.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="pointer" style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", minHeight: "50vh", justifyContent: "center" }}>
                  <Image src={logo} preview={false} width={75} className="cc-logo" />
                  <div className="inter-xxl">
                    {" "}
                    {item.start} <br/>{ item.nextStart? <span style={{ color: "black" }}>{item.nextStart}</span> : ""}<span className="text-red">{item.red}</span> {item.end}{" "}
                  </div>
                  <div className="inter-md text-grey" style={{ marginTop: "10px" }}>
                    {item.content}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}

      {/* Button at the bottom */}
      <Button
        type="primary"
        icon={<GoogleOutlined />}
        size="large"
        block
        style={{
          background: "#ff4d4f",
          borderColor: "#ff4d4f",
          position: "fixed",
          bottom: "20px",
          left: "0",
          right: "0",
          margin: "0 auto",
          width: "200px",
          zIndex: 9999,
          borderRadius: "20px",
        }}
        onClick={handleLogin}
      >
        Sign In with Google
      </Button>
    </div>
  );
};
