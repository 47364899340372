import { Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import logo from "../assets/images/logo.png";
function LoadingPage() {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f0f2f5",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <img
          src={logo}
          height={120}
          alt="Covenant Matrimony Logo"
          style={{
            marginBottom: 20,
            animation: "spin 3s linear infinite",
          }}
        />
        <Typography.Title level={4} style={{ fontSize: 24, marginBottom: 10 }}>
          Loading...
        </Typography.Title>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 40, color: "red" }} spin />} />
      </div>
    </div>
  );
}

export default LoadingPage;
