import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "./googleSignIn/config";
import { useQuery } from "@tanstack/react-query";
import LoadingPage from "../Components/loading";
import api from "../lib/axios";

const AuthContext = createContext();

const fetchUserData = async (uid) => {
  const response = await api.get(`/user/${uid}`);
  return response.data;
};

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        const userDetails = {
          uid: authUser.uid,
          email: authUser.email,
          displayName: authUser.displayName,
          photoURL: authUser.photoURL,
          emailVerified: authUser.emailVerified,
        };
        setUser(userDetails);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const { data: userData, isLoading } = useQuery({
    queryKey: ["users", user?.uid],
    enabled: !!user?.uid,
    // staleTime: 1000 * 60 * 5, // Cache for 5 minutes
    queryFn: () => fetchUserData(user.uid),
  });

  if (isLoading) return <LoadingPage />;
  else return <AuthContext.Provider value={{ user: { ...user, ...userData?.data }, loading }}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
