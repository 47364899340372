import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import TopBar from '../../Components/Onboarding/TopBar';
import BottomContent from '../../Components/Onboarding/BottomContent';
import { useMutation } from "@tanstack/react-query";
import api from "../../lib/axios";
import { useAuth } from "../../Components/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";

// import 

const { Content } = Layout;

const App = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    gender: '',
    name: '',
    dob: '',
    banner: '',
  });

  useEffect(() => {
    console.log({user})
    if (user?.isProfileComplete) {
      navigate("/profile")
    }
  },[])
  const addUserDetails = async (userDetails) => {
    const response = await api.patch(`/user/${user.uid}`, userDetails);
    return response.data;
  }
const addUserDetailsMutationHook = useMutation({
  mutationFn: addUserDetails,
})
  const nextStep = () => {
    if (currentStep < 5) {
      setCurrentStep(currentStep + 1);
    } else {
      formData.isProfileComplete = true;
      formData.id = user.uid
      addUserDetailsMutationHook.mutate(formData)
      setTimeout(() => {
        window.location.href = "/profile";
      },1000)
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <TopBar />
      <Content style={{ padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ width: '100%', maxWidth: '600px' }}>
          <BottomContent 
            currentStep={currentStep} 
            nextStep={nextStep} 
            prevStep={prevStep} 
            handleChange={handleChange} 
            values={formData} 
          />
        </div>
      </Content>
    </Layout>
  );
};

export default App;
