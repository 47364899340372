import React, { useState } from "react";
import { ArrowDownOutlined, HeartTwoTone, SearchOutlined, UserAddOutlined,CloseOutlined } from "@ant-design/icons";
import { Row, Col, Tooltip, Modal, Button,Avatar } from "antd";
const DynamicIsland = ({data, isLoading}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
    likedItemsList()
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const likedItemsList = () =>{
    let cards = []
    const likedItemDetails = data?.data?.liked_profiles ?? [];
    const myMatches = data?.data.my_matches ?? [];
    const shortListedList = myMatches.filter(({id}) => likedItemDetails.includes(id));
    console.log(shortListedList)
    shortListedList.map((item) => cards.push(
      <Row key={item.id} style={{ marginBottom: "10px", borderBottom: "1px solid #E5E5E5", paddingBottom: "6px" }} >
        <Col md={18} xs={16}>
        <Avatar src={item.profileImage} size={35} className="profile-avatar opensans" /> {item.fullName?.split(" ")[0]} | {item?.age}yrs
        </Col>
        <Col md={6} xs={8}>
        <Button className="opensans-sm" type="primary" ><UserAddOutlined /></Button>
        <Button className="opensans-sm" type="primary" style={{marginLeft:'5px'}}><CloseOutlined /></Button>
        </Col>
      </Row>
    ))
    return cards
  }
  return (
    <>
    <Row justify="center" style={{ marginTop: "20px", width: "100%" }}>
      <Col>
        <div className="dynamic-island">
          <div>
            <Tooltip title="Recieved Requests">
              <ArrowDownOutlined className="pointer" style={{ fontSize: "22px", color: "#468499" }} />
            </Tooltip>{" "}
            <span className=" notification-count inter-sm-bold">25</span>
          </div>
          <div>
            <Tooltip title="Shortlisted">
              <HeartTwoTone className="pointer" twoToneColor="#468499" style={{ fontSize: "22px" }}  onClick={showModal}/>
            </Tooltip>
            <span className=" notification-count inter-sm-bold">{data?.data?.liked_profiles?.length ?? 0}</span>
          </div>
          <div>
            <Tooltip title="Search">
              <SearchOutlined className="pointer" style={{ fontSize: "22px", color: "#468499" }} />
            </Tooltip>
          </div>
        </div>
      </Col>
    </Row>
    <Modal title="Short Listed" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {likedItemsList()}
      </Modal>
    </>
  );
};

export default DynamicIsland;
