import React, { useState } from "react";
import { Layout, Avatar, Button, Tabs, Input, Image, Tooltip, Modal, Space, Dropdown } from "antd";
import { SearchOutlined, HeartOutlined, MessageOutlined, HomeOutlined, LogoutOutlined } from "@ant-design/icons";
import woman from "../../assets/images/cc_woman.png";
import man from "../../assets/images/cc_man.png";
import man_profile from "../../assets/images/man_profile.png";
import woman_profile from "../../assets/images/woman_profile.png";
import { useAuth } from "../../Components/AuthContext";
import { auth } from "../../Components/googleSignIn/config";
import "./Profile.css";

const { Header, Footer, Content } = Layout;
const { TabPane } = Tabs;

function calculateAge(dob) {
  if (!dob)
    return ""
  const birthDate = new Date(dob.split("-").reverse().join("-")); // Convert to YYYY-MM-DD format
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

const App = () => {
  const { user } = useAuth();
  const items = [
    {
      key: "1",
      label: (
        <a href="/" rel="noopener noreferrer" onClick={() => auth.signOut()}>
          Log Out
        </a>
      ),
      icon: <LogoutOutlined />,
    },
  ];
  const [siteInprogress, setSiteInprogress] = useState(false);
  const openSiteInprogressModal = () => {
    setSiteInprogress(true);
  }
  return (
    <Layout className="layout">
      <Header className="header">
        <div className="header-content">
          <span className="user-name inter-xxl">
            Hi, <strong>{(user.name.split(' '))[0]}</strong>
          </span>
          <Tooltip title="search">
            <Button type="primary" shape="circle" style={{ color: "#000", backgroundColor: "#fff", width: "40px", height: "40px" }} icon={<SearchOutlined />} onClick={openSiteInprogressModal} />
          </Tooltip>
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomRight"
            arrow
          >
            <Avatar src={user.gender === "male" ? man : woman} className="avatar-icon" />
          </Dropdown>
        </div>
      </Header>

      {/* Content Section */}
      <Content >
        <div className="profile-card">
          <img
            src={user.gender === "male" ? man_profile : woman_profile} // You need to provide the actual image path here
            alt={user.name}
            className="profile-image"
            onClick={openSiteInprogressModal}
          />
          <div className="profile-details">
            <div className="profile-name-conatiner">
            <div className="profile-age">{calculateAge(user.dob)}</div>
            <div className="profile-name">{(user.name)?.split(" ")?.[0]} </div>
            </div>
          </div>

        </div>
      </Content>

      <Footer className="footer">
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab={<HomeOutlined style={{ fontSize: "20px" }} onClick={openSiteInprogressModal} />} key="1" />
          <TabPane tab={<HeartOutlined style={{ fontSize: "20px" }} onClick={openSiteInprogressModal} />} key="2" />
          <TabPane tab={<MessageOutlined style={{ fontSize: "20px" }} onClick={openSiteInprogressModal} />} key="3" />
        </Tabs>
      </Footer>
      <Modal centered open={siteInprogress} className="custom-modal" footer={null} closeIcon={null}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div style={{ fontSize: "35px" }}>👋</div>
        </div>
        <Space
          className="inter-md-bold"
          direction="horizontal"
          style={{ width: "100%", justifyContent: "center", textAlign: "center" }}
        >
          Congratulations, {user.name}!
        </Space>
        <Space
          className="inter"
          direction="horizontal"
          style={{ width: "100%", justifyContent: "center", marginTop: "10px", textAlign: "center" }}
        >
          You're among first to lock in your profile on Covenant Companion.
        </Space>
        <Space
          className="inter"
          direction="horizontal"
          style={{ width: "100%", justifyContent: "center", textAlign: "center", marginTop: "10px" }}        >
          We're in the middle of gathering profiles like yours and hope to help you start the search for your companion soon.
        </Space>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "15px", marginBottom: "15px" }}>
          <Button className="inter-bold" style={{ background: "#ff4d4f", borderColor: "#ff4d4f", color: "#fff", borderRadius: "20px" }} onClick={() => setSiteInprogress(false)}>
            Okay
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default App;
