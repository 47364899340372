import React, { useState } from "react";
import { Button, Input, Row, Col, Image, Form, DatePicker } from "antd";
import { motion } from "framer-motion";
import female from "../../assets/images/cc_xx.png";
import male from "../../assets/images/cc_xy.png";
import woman from "../../assets/images/cc_woman.png";
import man from "../../assets/images/cc_man.png";
import calender from "../../assets/images/cc_calendar.png";
import moment from "moment";

import "./Onboarding.css";

const banners = ["Reformed Baptist", "Baptist", "Presbyterian", "Dutch Reformed", "Anglican", "Non-Denominational", "Others"];

const BottomContent = ({ currentStep, nextStep, prevStep, handleChange, values }) => {
  const [active, setActive] = useState({ male: false, female: false });
  const [activeBanner, setActiveBanner] = useState("");

  const [form] = Form.useForm();

  const handleGender = (gender) => {
    setActive({ male: gender === "male", female: gender === "female" });
    values.gender = gender;
  };
  const handleDOBChange = (date, dateString) => {
    console.log({date, dateString});
    values.dob = dateString;
  };

  const handleBannerClick = (banner) => {
    setActiveBanner(banner);
    values.banner = banner;
  };

  const disabledDate = (current) => {
    // Disable dates that are after today's date or less than 18 years ago
    // return current && current > moment().subtract(18, 'years').endOf('day');
    const eighteenYearsAgo = moment().subtract(18, 'years');
    return current && current > eighteenYearsAgo.endOf('day'); // Disable all future dates and dates within the last 18 years
  };

  const createBannerList = () => {
    return (
      <div className="banner-container">
        {banners.map((banner) => (
          <div key={banner} className={"inter-md select-banner-card" + (activeBanner === banner ? " active" : "")} onClick={() => handleBannerClick(banner)}>
            {banner}
          </div>
        ))}
      </div>
    );
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="inter-xxl">
              How does God <span className="text-red">identify</span> you?
            </div>
            <div style={{ marginTop: "20px" }}>
              <Row gutter={16} className={"select-card" + (active.male ? " active" : "")} onClick={() => handleGender("male")}>
                <Col span={4}>
                  <Image src={male} preview={false} width={35} />
                </Col>
                <Col span={20} className="center-y inter-md-bold">
                  Male
                </Col>
              </Row>
              <Row gutter={16} className={"select-card" + (active.female ? " active" : "")} onClick={() => handleGender("female")}>
                <Col span={4}>
                  <Image src={female} preview={false} width={35} />
                </Col>
                <Col span={20} className="center-y inter-md-bold">
                  Female
                </Col>
              </Row>
              {!values.gender && <div className="error-message">Gender is required</div>}
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="inter-xxl">
              What do we call <span className="text-red">you</span>?
            </div>
            <div style={{ marginTop: "20px" }}>
              <Form.Item name="name" rules={[{ required: true, message: "Please enter your name" }]}>
                <Input
                  name="name"
                  value={values.name}
                  className="input-onboarding inter-md"
                  size="large"
                  placeholder="Enter your name here"
                  prefix={<Image style={{ paddingRight: 10 }} src={values.gender === "male" ? man : woman} preview={false} width={40} />}
                  onChange={handleChange}
                />
              </Form.Item>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="inter-xxl">
              When were you <span className="text-red">born </span>
              <div className="text-scratch">
                <span>again</span>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
              ?
            </div>
            <div style={{ marginTop: "20px" }}>
              <Form.Item name="dob" rules={[{ required: true, message: "Date of birth is required" }]}>
              <DatePicker  className="input-onboarding inter-md" size="large" placeholder="dd-mm-yyyy" format="DD-MM-YYYY"   inputReadOnly={true} suffixIcon={<Image style={{ paddingRight: 10 }} src={calender} preview={false} width={40} />} style={{ display: "flex", flexDirection: "row-reverse"}} onChange={handleDOBChange}/>
                {/* <Input name="dob" value={values.dob} className="input-onboarding inter-md" size="large" placeholder="dd-mm-yyyy" prefix={<Image style={{ paddingRight: 10 }} src={calender} preview={false} width={40} />} onChange={handleChange} /> */}
              </Form.Item>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="inter-xxl">
              What <span className="text-red">banner</span> do you fly?
            </div>
            <div style={{ marginTop: "20px" }}>
              {createBannerList()}
              {!values.banner && <div className="error-message">Banner is required</div>}
            </div>
          </>
        );
      case 5:
        return (
          <>
            <div className="inter-xxl">
              Welcome to <span className="text-red">Covenant</span>Companion.
            </div>
            <div className="text-grey inter-md">Please follow these House Rules.</div> 
            <div style={{ marginTop: "20px" }}>
            <div className="inter-bold" style={{marginBottom:'1px'}}>Be yourself.</div>
            <div className="inter">You're already one-of-a-kind, so don't go trying to be two-of-a-kind. Authenticity looks good on you.</div>
            <div className="inter-bold" style={{marginBottom:'1px', marginTop: "12px"}}>Be responsible.</div>
            <div className="inter">We're just here to introduce you to potential matches, but it's your job to engage with wisdom and discernment-don't swipe your way into trouble.</div>
            <div className="inter-bold" style={{marginBottom:'1px', marginTop: "12px"}}>Be respectful.</div>
            <div className="inter">Do unto others as you would want them to do unto you.</div>
            <div className="inter-bold" style={{marginBottom:'1px', marginTop: "12px"}}>Be safe.</div>
            <div className="inter">Trust is earned, not downloaded like an app. Always report bad behaviour.</div>
            <div className="inter-bold" style={{ marginTop: "10px",color:'#007be0' }}>Search for your companion wisely.</div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const handleNext = () => {
    form
      .validateFields()
      .then(() => {
        nextStep();
      })
      .catch(() => {});
  };

  return (
    <motion.div initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }} transition={{ duration: 0.2 }}>
      <Form form={form} layout="vertical" initialValues={values}>
        {renderStepContent()}
        <div style={{ display: "flex", marginTop: 20 }}>
          <Button className="inter-md btn-primary" onClick={handleNext}>
            {currentStep === 5 ? "Submit" : "Next"}
          </Button>
        </div>
      </Form>
    </motion.div>
  );
};

export default BottomContent;
